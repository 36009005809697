import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { closeIcon, navbarLogo, menuIcon, arrow, dropup, dropdown } from "../assets";
import { navLinks } from "../constants";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false)
  // const [toggleD, setToggleD] = useState(false);

  // const handleDropdownClick = () => {
  //   setToggleD(false); // Close the dropdown when a link is clicked

  return (
    <div className="fixed left-0 right-0 top-0 bg-white flex items-center justify-between md:px-[100px] px-4 md:py-6 py-4 shadow-lg">
      <Link to="/">
        <img src={navbarLogo} alt="" className="md:h-[60px] h-10" />
      </Link>
      <div className="md:flex hidden items-center gap-[80px]">
        <div className="flex items-center gap-12 font-normal text-lg">
          {navLinks.map((navLink) => (
            <div className="relative group/item hidden md:block">
              <div className="text-center cursor-pointer">
                {navLink.path ? (
                  <NavLink
                    key={navLink.id}
                    to={navLink.path}
                    className="hover:text-primary font-medium relative group/item flex items-center gap-1"
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "#493A64" : "",
                      };
                    }}
                  >
                    {navLink.linkName}
                  </NavLink>
                ) : (
                  <div className="flex items-center gap-1">
                    <p className="group-hover/item:text-primary font-medium py-4">
                      {navLink.linkName}
                    </p>
                    <img
                      src={arrow}
                      alt=""
                      className="w-[16px] h-[16px] group-hover/item:rotate-180 transition-transform duration-500"
                    />
                  </div>
                )}
              </div>
              {navLink.sublinks && (
                <div
                  className={`fixed top-[80px] w-full left-0 right-0 bg-white grid py-10 grid-cols-5 shadow-sm invisible group-hover/item:visible hover:visible border gap-8 md:px-[100px]`}
                >
                  {navLink.sublinks?.map((item) => (
                    <Link
                      key={item.id}
                      to={`/rental-inventory/${item.id}`}
                      className="text-xs text-black hover:text-primary cursor-pointer block"
                    >
                      {item?.catName}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex items-center gap-4">
          <a
            className="md:py-3 py-2 md:px-12 px-6 text-black bg-white hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
            href="mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website"
          >
            Contact Us
          </a>
        </div>
      </div>

      <div className="block md:hidden">
        <img
          src={toggle ? closeIcon : menuIcon}
          className="md:hidden block h-6 w-6 cursor-pointer"
          alt=""
          onClick={() => setToggle(!toggle)}
        />
        <div
          className={`${
            toggle ? "flex" : "hidden"
          } px-4 py-10 h-[95vh] bg-black bg-opacity-90 text-white absolute top-20 right-0 -my-2 w-full`}
        >
          <ul className="list-none flex flex-col gap-4 pb-[24px]">
            {navLinks.map((item) => (
              <div className="relative group/item ">
                <div className="cursor-pointer">
                  {item.path ? (
                    <NavLink
                      to={item.path}
                      className="group-hover/item:text-primary"
                      style={({ isActive }) => {
                        return {
                          color: isActive ? "#493A64" : "",
                        };
                      }}
                      onClick={() => {
                        setToggle(false);
                      }}
                    >
                      {item.linkName}
                    </NavLink>
                  ) : (
                    <div
                      className="relative flex items-center gap-4"
                      onClick={() => {
                        setToggleDropdown(!toggleDropdown);
                      }}
                    >
                      <p className=" group-hover/item:text-primary">
                        {item.linkName}
                      </p>
                      <img
                        src={toggleDropdown ? dropup : dropdown}
                        alt=""
                        className="block"
                      />
                    </div>
                  )}
                </div>
                {toggleDropdown && item.sublinks && (
                  <div
                    className={`grid p-2 grid-cols-2 shadow-sm gap-y-1 gap-x-12 overflow-y-auto scrollbar-hide h-[250px] mt-4`}
                  >
                    {item.sublinks?.map((item) => (
                      <Link
                        to={`/rental-inventory/${item.id}`}
                        className="text-xs py-2 hover:text-primary cursor-pointer block"
                        onClick={() => {
                          setToggle(false);
                        }}
                      >
                        {item?.catName}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div className="flex flex-col">
              <a
                className="md:py-3 py-2 md:px-12 px-6 text-black bg-white hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black w-full"
                href="mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website"
              >
                Contact Us
              </a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
