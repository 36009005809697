import React from "react";
import { useParams } from "react-router-dom";
import { rentalCats } from "../constants";
import { inventoryBg } from "../assets";

const InventoryDetails = () => {
  // const navigate = useNavigate();
  const { id } = useParams();

  const inventory = rentalCats.find((item) => item.id === parseInt(id));

  // Define a function to load images based on category
  const loadImages = (category) => {
    switch (category) {
      case 'arches-and-backgrounds':
        return require.context(`../assets/rentalCategories/arches-and-backgrounds`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'banquet-furniture':
        return require.context(`../assets/rentalCategories/banquet-furniture`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'bars-and-barware':
        return require.context(`../assets/rentalCategories/bars-and-barware`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'bounce-houses':
        return require.context(`../assets/rentalCategories/bounce-houses`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'cake-stands':
        return require.context(`../assets/rentalCategories/cake-stands`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'candle-holders':
        return require.context(`../assets/rentalCategories/candle-holders`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'candy-carts':
        return require.context(`../assets/rentalCategories/candy-carts`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'catering-equipment':
        return require.context(`../assets/rentalCategories/catering-equipment`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'centerpieces':
        return require.context(`../assets/rentalCategories/centerpieces`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'chairs-and-tables':
        return require.context(`../assets/rentalCategories/chairs-and-tables`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'charger-plates':
        return require.context(`../assets/rentalCategories/charger-plates`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'china':
        return require.context(`../assets/rentalCategories/china`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'concession-machines':
        return require.context(`../assets/rentalCategories/concession-machines`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'crowd-control':
        return require.context(`../assets/rentalCategories/crowd-control`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'dance-floors-and-staging':
        return require.context(`../assets/rentalCategories/dance-floors-and-staging`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'decor':
        return require.context(`../assets/rentalCategories/decor`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'dinnerware':
        return require.context(`../assets/rentalCategories/dinnerware`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'draping':
        return require.context(`../assets/rentalCategories/draping`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'drinkware':
        return require.context(`../assets/rentalCategories/drinkware`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'event-space':
        return require.context(`../assets/rentalCategories/event-space`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'flatware':
        return require.context(`../assets/rentalCategories/flatware`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'flowers-plants-and-trees':
        return require.context(`../assets/rentalCategories/flowers-plants-and-trees`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'lighting':
        return require.context(`../assets/rentalCategories/lighting`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'linen':
        return require.context(`../assets/rentalCategories/linen`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'lounge-seating':
        return require.context(`../assets/rentalCategories/lounge-seating`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'marquee-and-led-signs':
        return require.context(`../assets/rentalCategories/marquee-and-led-signs`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'pedestals-and-plinths':
        return require.context(`../assets/rentalCategories/pedestals-and-plinths`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'statues-and-cutouts':
        return require.context(`../assets/rentalCategories/statues-and-cutouts`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'tables':
        return require.context(`../assets/rentalCategories/tables`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'tents-and-canopies':
        return require.context(`../assets/rentalCategories/tents-and-canopies`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'vases':
        return require.context(`../assets/rentalCategories/vases`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      case 'venue-event-space':
        return require.context(`../assets/rentalCategories/venue-event-space`, false, /\.(png|jpeg|jpg|jfif|svg)$/);
      // Add cases for other categories
      default:
        return () => [];
    }
  };

  // Import images for the current inventory category
  const importImages = loadImages(inventory.catNamePath);
  const images = importImages.keys().map(importImages);
  console.log(images)
  
  const styles = {
    backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${inventoryBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  };

  return (
    <div className="flex flex-col md:pt-[100px]">
      <div style={styles} className="h-[300px] md:mb-[60px] mb-10"></div>
      <div className='md:mb-[60px] mb-10 md:px-[100px] px-4'>
        <div className="flex flex-col">
          <h2 className="md:text-3xl text-xl font-bold md:mb-6 mb-4">
            {inventory.catName}
          </h2>
        </div>
        <div className="mb-12 grid md:grid-cols-4 grid-cols-1 gap-6">
          {images.map((image, index) => (
            <div key={index} className="rounded-xl border border-black p-4">
              <img src={image} alt="" className='h-[200px] md:h-[160px] rounded-[4px] w-full mb-6' />
              <div className="flex flex-col">
                  <span className="font-bold md:text-base text-sm">
                    Inventory {index + 1}
                  </span>
                </div>
            </div>
          ))}
        </div>
        <div className="flex w-full justify-center">
          <a 
            className="py-3 md:px-12 px-6 bg-primary hover:bg-black text-white font-semibold"
            href='mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website'
          >
            Contact us for more 
          </a>
        </div>
      </div>
    </div>
  );
};

export default InventoryDetails;
