import React from 'react'
import { aboutImage } from '../assets'

const About = () => {

  return (
    <div className='bg-[#F9F9F9] md:px-[100px] px-4 md:py-16 py-10 border-b-[10px] border-primary flex md:flex-row flex-col-reverse items-center gap-12'>
      <img src={aboutImage} alt="" className="md:block md:w-[35%]" />
      <div className="">
        <h2 className="md:text-3xl text-xl font-bold mb-4">
          Our Mission
        </h2>
        <p className="mb-6">
          At BrightView Rentals, our mission is to provide exceptional event planning equipment and services that transform ordinary moments into extraordinary memories.  
          <br /><br /> 
          We are dedicated to helping our clients bring their visions to life through high-quality products, personalized service, and a commitment to excellence. Our goal is to make every event, no matter the size or occasion, a seamless and memorable experience for all our clients. <span className="font-bold">-Brightview Rentals.</span>
        </p>
        <div className="">
          <a 
            className="py-3 md:px-12 px-6 bg-primary hover:bg-black text-white font-semibold"
            href='mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website'
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default About