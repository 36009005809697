import React from "react";
import { eventTypes } from "../constants";
import { About, Vision } from "../components";
import { eventHeroImage } from "../assets";
// import { useNavigate } from 'react-router-dom'

const EventPlanningPage = ({ servicesNo, bText }) => {

  // const navigate = useNavigate()

  return (
    <div className="">
      <div
        className={`flex
        md:flex-row flex-col bg-[#F3F3F3] justify-between mb-10 h-[100vh]`}
      >
        <div className="flex flex-col justify-center md:pl-[100px] px-6 pt-24">
          <h1 className="md:text-5xl text-2xl font-bold flex items-center gap-2 md:mb-2 mb-1 text-primary">
            Event Planning.
          </h1>
          <p className="md:w-[85%] w-full mb-6">
            At BrightView Rentals, our comprehensive event planning services
            ensure that every detail of your event is meticulously managed from
            start to finish. Whether you’re hosting a small gathering or a
            large-scale celebration, our team of experienced planners works
            closely with you to understand your vision and bring it to life.
          </p>
          <div className="flex items-center gap-4 mb-6">
            <a
              className="py-3 md:px-12 px-6 bg-primary hover:bg-black text-white font-semibold"
              href="mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website"
            >
              Get Started
            </a>
            <a
              className="md:py-3 py-2 md:px-12 px-6 text-black bg-white hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
              href="mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website"
            >
              Contact Us
            </a>
          </div>
        </div>
        <img src={eventHeroImage} alt="" className="md:w-1/2 w-full md:block" />
      </div>
      <div className="md:my-[60px] my-10 md:px-[100px] px-4">
        {eventTypes
          .filter((_, index) => (servicesNo ? index < servicesNo : true))
          .map((item, index) => (
            <div
              key={index}
              className={`flex ${
                index % 2 === 0
                  ? "md:flex-row flex-col bg-[#F3F3F3]"
                  : "md:flex-row-reverse flex-col bg-white"
              } justify-between mb-10`}
            >
              <div className="flex flex-col justify-center md:pl-[60px] px-6 py-10">
                <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
                  {item.hText}
                </h3>
                <p className="md:w-[85%] w-full mb-6">{item.pText}</p>
                <div className="">
                  <a
                    className="py-3 md:px-12 px-6 bg-primary hover:bg-black text-white font-semibold"
                    href="mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
              <img
                src={item.imagePicture}
                alt=""
                className="md:w-1/2 w-full md:block"
              />
            </div>
          ))}
      </div>
      <Vision />
      <About />
    </div>
  );
};

export default EventPlanningPage;
