import React from 'react'
import { visionImage } from '../assets'

const Vision = () => {

  const styles = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${visionImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div style={styles} className='md:mx-[100px] mx-4 md:h-[250px] md:my-[80px] my-10 flex flex-col justify-center rounded-lg text-white md:px-[100px] px-4 md:py-0 py-10'>
      <h2 className="md:text-3xl text-xl font-bold md:mb-4 mb-2">
          Our Vision
        </h2>
        <p className="md:w-[100%] w-full">
          Our vision at BrightView Rentals is to be the premier choice for event rentals services, renowned for our innovation, reliability, and outstanding customer service. We strive to set the industry standard by continuously expanding our product offerings and enhancing our service quality.
        </p>
    </div>
  )
}

export default Vision