import React from 'react'
import { customerImage } from '../assets'

const Customers = () => {

  return (
    <div className='my-16 flex md:flex-row flex-col-reverse items-center w-full md:px-[100px] px-4'>
      <img src={customerImage} alt="" className="md:block md:w-1/2 w-full object-cover md:h-[450px]" />
      <div className="md:w-1/2 bg-white text-primary md:px-[60px] px-4 flex flex-col justify-center md:h-[450px] py-10 md:py-0 border border-1 border-primary border-b-0 md:border-l-0">
        <h2 className="md:text-3xl text-xl font-bold mb-4">
          Transforming Dreams into Reality
        </h2>
        <p className="mb-6">
          Join the countless satisfied customers who have trusted BrightView Rentals to make their events extraordinary. Get exceptional value without compromising on quality.
        </p>
        <div className="">
          <a 
            className="py-3 md:px-12 px-6 bg-primary hover:bg-black text-white font-semibold"
            href='mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website'
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default Customers