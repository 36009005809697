import { eventType1, eventType2, eventType3, projectIcon, ratingIcon, rentalImage1, rentalImage10, rentalImage11, rentalImage12, rentalImage13, rentalImage14, rentalImage15, rentalImage16, rentalImage17, rentalImage18, rentalImage19, rentalImage2, rentalImage20, rentalImage21, rentalImage3, rentalImage4, rentalImage5, rentalImage6, rentalImage7, rentalImage8, rentalImage9, satisfactionIcon, serviceImage, serviceImage2, serviceImage3, serviceImage4, } from "../assets";

export const navLinks = [
  {
    id: 1,
    linkName: 'Home',
    path: '/'
  },
  {
    id: 2,
    linkName: 'About',
    path: '/about'
  },
  {
    id: 3,
    linkName: 'Services',
    path: '/services'
  },
  {
    id: 4,
    linkName: 'Rental Inventory',
    // path: '/rental-inventory',
    sublinks: [
      {
        id: 1,
        catName: 'Arches & Backgrounds',
        catImage: rentalImage1,
      },
      {
        id: 2,
        catName: 'Banquet Furniture',
        catImage: rentalImage1,
      },
      {
        id: 3,
        catName: 'Bars & Barware',
        catImage: rentalImage1,
      },
      {
        id: 4,
        catName: 'Bounce Houses',
        catImage: rentalImage1,
      },
      {
        id: 5,
        catName: 'Cake Stands',
        catImage: rentalImage1,
      },
      {
        id: 6,
        catName: 'Candle Holders',
        catImage: rentalImage1,
      },
      {
        id: 7,
        catName: 'Candy Carts',
        catImage: rentalImage1,
      },
      {
        id: 8,
        catName: 'Catering Equipments',
        catImage: rentalImage1,
      },
      {
        id: 9,
        catName: 'Centerpieces',
        catImage: rentalImage1,
      },
      {
        id: 10,
        catName: 'Chairs & Tables',
        catImage: rentalImage1,
      },
      {
        id: 11,
        catName: 'Charger Plates',
        catImage: rentalImage1,
      },
      {
        id: 12,
        catName: 'China',
        catImage: rentalImage1,
      },
      {
        id: 13,
        catName: 'Concession Machines',
        catImage: rentalImage1,
      },
      {
        id: 14,
        catName: 'Crowd Control',
        catImage: rentalImage1,
      },
      {
        id: 15,
        catName: 'Dance Floors & Staging',
        catImage: rentalImage1,
      },
      {
        id: 16,
        catName: 'Decor',
        catImage: rentalImage1,
      },
      {
        id: 17,
        catName: 'Dinnerware',
        catImage: rentalImage1,
      },
      {
        id: 18,
        catName: 'Draping',
        catImage: rentalImage1,
      },
      {
        id: 19,
        catName: 'Drinkware',
        catImage: rentalImage1,
      },
      {
        id: 20,
        catName: 'Event Space',
        catImage: rentalImage1,
      },
      {
        id: 21,
        catName: 'Flatware',
        catImage: rentalImage1,
      },
      {
        id: 22,
        catName: 'Flowers, Plants & Trees',
        catImage: rentalImage1,
      },
      {
        id: 23,
        catName: 'Lighting',
        catImage: rentalImage1,
      },
      {
        id: 24,
        catName: 'Linen',
        catImage: rentalImage1,
      },
      {
        id: 25,
        catName: 'Lounge Seating',
        catImage: rentalImage1,
      },
      {
        id: 26,
        catName: 'Marquee & LED Signs',
        catImage: rentalImage1,
      },
      {
        id: 27,
        catName: 'Pedestals & Plinths',
        catImage: rentalImage1,
      },
      {
        id: 28,
        catName: 'Statues & Cutouts',
        catImage: rentalImage1,
      },
      {
        id: 29,
        catName: 'Tables',
        catImage: rentalImage1,
      },
      {
        id: 30,
        catName: 'Tents & Canopies',
        catImage: rentalImage1,
      },
      {
        id: 31,
        catName: 'Vases',
        catImage: rentalImage1,
      },
      {
        id: 32,
        catName: 'Venue (Event Space)',
        catImage: rentalImage1,
      },
    ]
  },
  {
    id: 5,
    linkName: 'Event Planning',
    path: '/event-planning'
  },
];

export const statistics = [
  {
    id: 1,
    stat: '100%',
    statDescription: 'Client Oriented',
    statImage: projectIcon,
  },
  {
    id: 2,
    stat: 'Very',
    statDescription: 'Professional',
    statImage: satisfactionIcon,
  },
  {
    id: 3,
    stat: '100%',
    statDescription: 'Clients Satisfaction',
    statImage: ratingIcon,
  },
]

export const customers = [
  {
    id: 1,
    customerName: 'Dr. Thoyinstar, CEO of Compass Inc',
    summary: "We serve in developing and maintaining skills related to personal health care needs.. We will provide services at any hour of the day and any day of the week, including holidays."
  },
  {
    id: 2,
    customerName: 'Dr. DoyinMoon, CEO of Mathset Inc',
    summary: "We serve in developing and maintaining skills related to community membership.."
  },
]


export const rentals = [
  {
    id: 1,
    rentalName: 'Inventory 1',
    catNamePath: 'arches-and-backgrounds',
    rentalImage: rentalImage1
  },
  {
    id: 2,
    rentalName: 'Inventory 2',
    catNamePath: 'banquet-furniture',
    rentalImage: rentalImage2
  },
  {
    id: 3,
    rentalName: 'Inventory 3',
    catNamePath: 'bars-and-barware',
    rentalImage: rentalImage3
  },
  {
    id: 4,
    rentalName: 'Inventory 4',
    catNamePath: 'bounce-houses',
    rentalImage: rentalImage4
  },
  {
    id: 5,
    rentalName: 'Inventory 5',
    catNamePath: 'cake-stands',
    rentalImage: rentalImage5
  },
  {
    id: 6,
    rentalName: 'Inventory 6',
    catNamePath: 'candle-holders',
    rentalImage: rentalImage6
  },
  {
    id: 7,
    rentalName: 'Inventory 7',
    catNamePath: 'candy-carts',
    rentalImage: rentalImage7
  },
  {
    id: 8,
    rentalName: 'Inventory 8',
    catNamePath: 'catering-equipment',
    rentalImage: rentalImage8
  },
  {
    id: 9,
    rentalName: 'Inventory 9',
    catNamePath: 'centerpieces',
    rentalImage: rentalImage9
  },
  {
    id: 10,
    rentalName: 'Inventory 10',
    catNamePath: 'chairs-and-tables',
    rentalImage: rentalImage10
  },
  {
    id: 11,
    rentalName: 'Inventory 11',
    catNamePath: 'charger-plates',
    rentalImage: rentalImage11
  },
  {
    id: 12,
    rentalName: 'Inventory 12',
    catNamePath: 'china',
    rentalImage: rentalImage12
  },
  {
    id: 13,
    rentalName: 'Inventory 13',
    catNamePath: 'concession-machines',
    rentalImage: rentalImage13
  },
  {
    id: 14,
    rentalName: 'Inventory 14',
    catNamePath: 'crowd-control',
    rentalImage: rentalImage14
  },
  {
    id: 15,
    rentalName: 'Inventory 15',
    catNamePath: 'dance-floors-and-staging',
    rentalImage: rentalImage15
  },
  {
    id: 16,
    rentalName: 'Inventory 16',
    catNamePath: 'decor',
    rentalImage: rentalImage16
  },
  {
    id: 17,
    rentalName: 'Inventory 17',
    catNamePath: 'dinnerware',
    rentalImage: rentalImage17
  },
  {
    id: 18,
    rentalName: 'Inventory 18',
    catNamePath: 'draping',
    rentalImage: rentalImage18
  },
  {
    id: 19,
    rentalName: 'Inventory 19',
    catNamePath: 'drinkware',
    rentalImage: rentalImage19
  },
  {
    id: 20,
    rentalName: 'Inventory 20',
    catNamePath: 'event-space',
    rentalImage: rentalImage20
  },
  {
    id: 21,
    rentalName: 'Inventory 21',
    catNamePath: 'flatware',
    rentalImage: rentalImage21
  },
  {
    id: 22,
    rentalName: 'Inventory 22',
    catNamePath: 'flowers-plants-and-trees',
    rentalImage: rentalImage21
  },
  {
    id: 23,
    rentalName: 'Inventory 23',
    catNamePath: 'lighting',
    rentalImage: rentalImage21
  },
  {
    id: 24,
    rentalName: 'Inventory 24',
    catNamePath: 'linen',
    rentalImage: rentalImage21
  },
  {
    id: 25,
    rentalName: 'Inventory 25',
    catNamePath: 'lounge-seating',
    rentalImage: rentalImage21
  },
  {
    id: 26,
    rentalName: 'Inventory 26',
    catNamePath: 'marquee-and-led-signs',
    rentalImage: rentalImage21
  },
  {
    id: 27,
    rentalName: 'Inventory 27',
    catNamePath: 'pedestals-and-plinths',
    rentalImage: rentalImage21
  },
  {
    id: 28,
    rentalName: 'Inventory 28',
    catNamePath: 'statues-and-cutouts',
    rentalImage: rentalImage21
  },
  {
    id: 29,
    rentalName: 'Inventory 29',
    catNamePath: 'tables',
    rentalImage: rentalImage21
  },
  {
    id: 30,
    rentalName: 'Inventory 30',
    catNamePath: 'tents-and-canopies',
    rentalImage: rentalImage21
  },
  {
    id: 31,
    rentalName: 'Inventory 31',
    catNamePath: 'vases',
    rentalImage: rentalImage21
  },
  {
    id: 32,
    rentalName: 'Inventory 32',
    catNamePath: 'venue-event-space',
    rentalImage: rentalImage21
  },
  
]

export const servicesTypes = [
  {
    id: 1,
    hText: 'Birthday Parties',
    pText: "Whether you're planning a whimsical children's birthday bash or an elegant adult soirée, We has everything you need to make your party unforgettable. Our extensive range of party supplies, decorations, and entertainment options ensures that your celebration will be fun, festive, and flawlessly executed.",
    imagePicture: serviceImage
  },
  {
    id: 2,
    hText: 'Weddings & Marriage Proposals',
    pText: "At BrightView Rentals, we understand that weddings and marriage proposals are some of the most important moments in your life. We offer a wide selection of elegant and high-quality rental items to ensure your special day is everything you've dreamed of and more.",
    imagePicture: serviceImage2
  },
  {
    id: 3,
    hText: 'Ceremonies',
    pText: "From intimate gatherings to grand celebrations, We provide everything you need to host a flawless ceremony. Our extensive inventory and expert team are here to help you create a meaningful and memorable event that honors your traditions and style.",
    imagePicture: serviceImage3
  },
  {
    id: 4,
    hText: 'Baby Showers',
    pText: "Celebrate the upcoming arrival of your little one with a beautifully organized baby shower. BrightView Rentals offers a wide range of adorable and stylish rental items to create a warm and inviting atmosphere for your guests.",
    imagePicture: serviceImage4
  },
]
export const eventTypes = [
  {
    id: 1,
    hText: 'Wedding Planning',
    pText: "Your wedding day is one of the most significant moments of your life, and we're here to make it truly unforgettable. Our dedicated wedding planning services cover every aspect of your big day, from the initial planning stages to the final farewell. We offer customized solutions tailored to your unique style and preferences.",
    imagePicture: eventType1
  },
  {
    id: 2,
    hText: 'Party Planning',
    pText: "From birthdays and anniversaries to holiday gatherings and themed parties, BrightView Rentals specializes in planning parties that are both fun and memorable. Our party planning services include theme development, decor, entertainment, and catering coordination.",
    imagePicture: eventType2
  },
  {
    id: 3,
    hText: 'Corporate Events',
    pText: "We understands the importance of well-executed corporate events in achieving your business objectives. Our corporate event planning services cover everything from conferences and seminars to product launches and team-building activities. We are your go-to option.",
    imagePicture: eventType3
  },
]
export const rentalCats = [
  {
    id: 1,
    catName: 'Arches & Backgrounds',
    catNamePath: 'arches-and-backgrounds',
    catImage: rentalImage1,
  },
  {
    id: 2,
    catName: 'Banquet Furniture',
    catNamePath: 'banquet-furniture',
    catImage: rentalImage1,
  },
  {
    id: 3,
    catName: 'Bars & Barware',
    catNamePath: 'bars-and-barware',
    catImage: rentalImage1,
  },
  {
    id: 4,
    catName: 'Bounce Houses',
    catNamePath: 'bounce-houses',
    catImage: rentalImage1,
  },
  {
    id: 5,
    catName: 'Cake Stands',
    catNamePath: 'cake-stands',
    catImage: rentalImage1,
  },
  {
    id: 6,
    catName: 'Candle Holders',
    catNamePath: 'candle-holders',
    catImage: rentalImage1,
  },
  {
    id: 7,
    catName: 'Candy Carts',
    catNamePath: 'candy-carts',
    catImage: rentalImage1,
  },
  {
    id: 8,
    catName: 'Catering Equipments',
    catNamePath: 'catering-equipment',
    catImage: rentalImage1,
  },
  {
    id: 9,
    catName: 'Centerpieces',
    catNamePath: 'centerpieces',
    catImage: rentalImage1,
  },
  {
    id: 10,
    catName: 'Chairs & Tables',
    catNamePath: 'chairs-and-tables',
    catImage: rentalImage1,
  },
  {
    id: 11,
    catName: 'Charger Plates',
    catNamePath: 'charger-plates',
    catImage: rentalImage1,
  },
  {
    id: 12,
    catName: 'China',
    catNamePath: 'china',
    catImage: rentalImage1,
  },
  {
    id: 13,
    catName: 'Concession Machines',
    catNamePath: 'concession-machines',
    catImage: rentalImage1,
  },
  {
    id: 14,
    catName: 'Crowd Control',
    catNamePath: 'crowd-control',
    catImage: rentalImage1,
  },
  {
    id: 15,
    catName: 'Dance Floors & Staging',
    catNamePath: 'dance-floors-and-staging',
    catImage: rentalImage1,
  },
  {
    id: 16,
    catName: 'Decor',
    catNamePath: 'decor',
    catImage: rentalImage1,
  },
  {
    id: 17,
    catName: 'Dinnerware',
    catNamePath: 'dinnerware',
    catImage: rentalImage1,
  },
  {
    id: 18,
    catName: 'Draping',
    catNamePath: 'draping',
    catImage: rentalImage1,
  },
  {
    id: 19,
    catName: 'Drinkware',
    catNamePath: 'drinkware',
    catImage: rentalImage1,
  },
  {
    id: 20,
    catName: 'Event Space',
    catNamePath: 'event-space',
    catImage: rentalImage1,
  },
  {
    id: 21,
    catName: 'Flatware',
    catNamePath: 'flatware',
    catImage: rentalImage1,
  },
  {
    id: 22,
    catName: 'Flowers, Plants & Trees',
    catNamePath: 'flowers-plants-and-trees',
    catImage: rentalImage1,
  },
  {
    id: 23,
    catName: 'Lighting',
    catNamePath: 'lighting',
    catImage: rentalImage1,
  },
  {
    id: 24,
    catName: 'Linen',
    catNamePath: 'linen',
    catImage: rentalImage1,
  },
  {
    id: 25,
    catName: 'Lounge Seating',
    catNamePath: 'lounge-seating',
    catImage: rentalImage1,
  },
  {
    id: 26,
    catName: 'Marquee & LED Signs',
    catNamePath: 'marquee-and-led-signs',
    catImage: rentalImage1,
  },
  {
    id: 27,
    catName: 'Pedestals & Plinths',
    catNamePath: 'pedestals-and-plinths',
    catImage: rentalImage1,
  },
  {
    id: 28,
    catName: 'Statues & Cutouts',
    catNamePath: 'statues-and-cutouts',
    catImage: rentalImage1,
  },
  {
    id: 29,
    catName: 'Tables',
    catNamePath: 'tables',
    catImage: rentalImage1,
  },
  {
    id: 30,
    catName: 'Tents & Canopies',
    catNamePath: 'tents-and-canopies',
    catImage: rentalImage1,
  },
  {
    id: 31,
    catName: 'Vases',
    catNamePath: 'vases',
    catImage: rentalImage1,
  },
  {
    id: 32,
    catName: 'Venue (Event Space)',
    catNamePath: 'venue-event-space',
    catImage: rentalImage1,
  },
]