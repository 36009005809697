import React from 'react'
import { useNavigate } from 'react-router-dom'
import { servicesTypes } from '../constants'

const ServiceTypes = ({servicesNo, bText}) => {

  const navigate = useNavigate()

  return (
    <div className='md:my-[60px] my-10 md:px-[100px] px-4'>
      <div className="flex flex-col items-center text-center mb-10">
        <h2 className="md:text-3xl text-xl font-bold md:mb-4 mb-2">
          Services we offer
        </h2>
        <p className="md:w-[64%] w-full">
          Here are some services we offer our clients.
        </p>
      </div>
      {servicesTypes
        .filter((_, index) => servicesNo ? index < servicesNo : true)
        .map((item, index) => (
          <div key={index} className={`flex ${(index % 2 === 0) ? 'md:flex-row flex-col bg-[#F3F3F3]' : 'md:flex-row-reverse flex-col bg-white'} justify-between mb-10`}>
            <div className="flex flex-col justify-center md:pl-[60px] px-6 py-10">
              <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
                {item.hText}
              </h3>
              <p className="md:w-[85%] w-full mb-6">
                {item.pText}
              </p>
              <div className="">
                <a 
                  className="py-3 md:px-12 px-6 bg-primary hover:bg-black text-white font-semibold"
                  href='mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website'
                >
                  Contact Us
                </a>
              </div>
            </div>
            <img src={item.imagePicture} alt="" className="md:w-1/2 w-full md:block" />
          </div>
        ))
      }

      {bText && (
        <div className="flex justify-center">
          <button
            className="md:py-3 py-2 px-6 text-black bg-transparent hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
            onClick={() => navigate('/services')}
          >
            View all services
          </button>
        </div>
      )}
    </div>
  )
}

export default ServiceTypes