import React from 'react'
// import { useNavigate } from 'react-router-dom'

const HeroSection = ({hText, pText, bgImage}) => {

  // const navigate = useNavigate()

  const styles = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div style={styles} className='h-[100vh] md:pt-[80px] text-white'>
      <div className="md:w-full flex flex-col justify-center items-center h-full text-center">
        <h1 className="text-2xl md:text-5xl md:leading-tight font-bold mb-4">
          {hText}
        </h1>
        <p className="md:w-[78%] w-[95%] mb-6">
          {pText}
        </p>
        <div className="flex items-center gap-4 mb-6">
          <a 
            className="py-3 md:px-12 px-6 bg-primary hover:bg-black text-white font-semibold"
            href='mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website'
          >
            Get Started
          </a>
          <a 
            className="md:py-3 py-2 md:px-12 px-6 text-black bg-white hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
            href='mailto:brightviewrentals@gmail.com?subject=Mail from BrightView Rentals Website'
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default HeroSection