import React from 'react'
import { About, HeroSection, ServiceTypes, Vision } from '../components'
import { aboutHeroBg } from '../assets'

const AboutPage = () => {


  return (
    <div className=''>
      <HeroSection 
        hText='About BrightView Rentals.'
        pText='BrightView Rentals is a leading provider of event planning equipment and resources. We are passionate about helping our clients create unforgettable experiences, no matter the occasion. Our extensive inventory and exceptional customer service make us the go-to choice for all your event needs.'
        bgImage={aboutHeroBg}
      />
      <Vision />
      <About />
      <ServiceTypes bText={true} />
    </div>
  )
}

export default AboutPage