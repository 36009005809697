import React from 'react'
import { About, Customers, HeroSection, ServiceTypes, Vision } from '../components'
import { heroBg } from '../assets'

const HomePage = () => {
  return (
    <div>
      <HeroSection 
        hText='Welcome to BrightView Rentals'
        pText='At BrightView Rentals, we provide top-quality event planning equipment and resources to make your special moments unforgettable. Whether you’re planning a birthday party, a marriage proposal, a ceremony, a baby shower, or a wedding, we have everything you need to create a stunning and memorable event.'
        bgImage={heroBg}
      />
      <Customers />
      <ServiceTypes servicesNo={3} bText={true} />
      <Vision />
      <About />
    </div>
  )
}

export default HomePage