import React from 'react'
import { About, ServiceTypes, Vision } from '../components'

const ServicePage = () => {
  return (
    <div className='md:pt-[100px] pt-[60px]'>
      <ServiceTypes />
      <Vision />
      <About />
    </div>
  )
}

export default ServicePage